import { Avatar, Badge, List, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../Startups.module.css";
import { getTeam, teamSelector } from "../startupsSlice";

export function Team(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(teamSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getTeam(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <>
      <Badge.Ribbon text="Team" color="cyan">
        <div className="container" style={{ paddingBottom: "10px" }}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      item.user_id ? (
                        <Link to={`/users/${item.user_id}`}>
                          <Avatar src={item.avatar} />
                        </Link>
                      ) : (
                        <Avatar src={item.avatar} />
                      )
                    }
                    title={
                      item.user_id ? (
                        <Link to={`/users/${item.user_id}`}>
                          <span className="text-link">{item.name}</span>
                        </Link>
                      ) : (
                        <span>{item.name}</span>
                      )
                    }
                    description={item.role}
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
}
