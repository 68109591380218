import {
  BankOutlined,
  IdcardOutlined,
  LogoutOutlined,
  PartitionOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Dropdown, Grid, Layout, Menu, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import logoSm from "../../assets/img/logo_nav.png";
import { authSelector, logout, validate } from "../auth/authSlice";
import { Companies } from "../companies/Companies";
import { CompanyDetails } from "../companies/CompanyDetail";
import { Profile } from "../profile/Profile";
import { StartupDetails } from "../startups/StartupDetail";
import { Startups } from "../startups/Startups";
import { Tags } from "../tags/Tags";
import { UserDetails } from "../users/UserDetail";
import { Users } from "../users/Users";
import styles from "./Nav.module.css";

export function Nav() {
  const { currentUser } = useSelector(authSelector);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Header, Content } = Layout;
  let { id } = useParams();

  const getContent = () => {
    let path = location.pathname;
    switch (true) {
      case /^\/startups$/.test(path):
        return <Startups />;
      case /startups\/([\s\S]*)$/.test(path):
        return <StartupDetails id={id} />;
      case /^\/users$/.test(path):
        return <Users />;
      case /users\/([\s\S]*)$/.test(path):
        return <UserDetails id={id} />;
      case /^\/profile$/.test(path):
        return <Profile />;
      case /^\/companies$/.test(path):
        return <Companies />;
      case /companies\/([\s\S]*)$/.test(path):
        return <CompanyDetails id={id} />;
      case /^\/tags$/.test(path):
        return <Tags />;
      default:
        return <Startups />;
    }
  };

  useEffect(() => {
    dispatch(validate());
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      history.push("/startups");
    }
  }, [location]);

  const userMenu = (
    <Menu>
      <Menu.Item
        className={styles.navItem}
        onClick={() => history.push("/profile")}
      >
        <UserOutlined className={styles.icon} />
        Profile
      </Menu.Item>
      <Menu.Item className={styles.navItem} onClick={() => dispatch(logout())}>
        <LogoutOutlined className={styles.icon} />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout className={styles.layout}>
        <Header className={styles.header} style={{ padding: 0 }}>
          <Row>
            <Col span={4}>
              <div className={styles.logo}>
                <img
                  src={screens.lg ? logo : logoSm}
                  height="30"
                  alt="logo"
                  className="pointer"
                  onClick={() => history.push("/startups")}
                />
              </div>
            </Col>
            <Col span={16}>
              <Menu
                className={styles.nav}
                selectedKeys={location.pathname.split("/")[1]}
                mode="horizontal"
                onSelect={(data) => history.push(`/${data.key}`)}
              >
                <Menu.Item key="startups">
                  <PartitionOutlined className={styles.icon} />
                  {screens.lg && "Startups"}
                </Menu.Item>
                <Menu.Item key="users">
                  <IdcardOutlined className={styles.icon} />
                  {screens.lg && "Users"}
                </Menu.Item>
                <Menu.Item key="companies">
                  <BankOutlined className={styles.icon} />
                  {screens.lg && "Companies"}
                </Menu.Item>
                <Menu.Item key="tags">
                  <TagOutlined className={styles.icon} />
                  {screens.lg && "Tags"}
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={4} className={styles.avatar}>
              <Space size="large" className="mr-20">
                <Dropdown overlay={userMenu}>
                  <Avatar
                    size="large"
                    src={currentUser.avatar_url}
                    className="pointer"
                  />
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout className={styles.mainContent}>
          {/* <Sider breakpoint="lg" collapsedWidth="80" className={styles.slider}>
            <Menu
              defaultSelectedKeys={["1"]}
              mode="inline"
              className={styles.menu}
            >
              <Menu.Item
                key="1"
                className={styles.item}
                icon={<PieChartOutlined />}
              >
                Option 1
              </Menu.Item>
              <Menu.Item
                key="2"
                className={styles.item}
                icon={<DesktopOutlined />}
              >
                Option 2
              </Menu.Item>
            </Menu>
          </Sider> */}
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            {getContent()}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
