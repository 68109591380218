import React from "react";
import { Route, Switch } from "react-router-dom";
import { Login } from "./features/auth/Login";
import { Nav } from "./features/nav/Nav";

export const RootRouter = () => (
  <Switch>
    <Route exact path="/sign_in" component={Login} />
    <Route exact path="/" component={Nav} />
    <Route exact path="/startups" component={Nav} />
    <Route exact path="/startups/:id" component={Nav} />
    <Route exact path="/users" component={Nav} />
    <Route exact path="/users/:id" component={Nav} />
    <Route exact path="/companies" component={Nav} />
    <Route exact path="/companies/:id" component={Nav} />
    <Route exact path="/tags" component={Nav} />
    <Route exact path="/profile" component={Nav} />
  </Switch>
);
