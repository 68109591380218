import { Avatar, Badge, Card, List, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../Startups.module.css";
import {
  evaluatorsSelector,
  getEvaluation,
  getEvaluators,
} from "../startupsSlice";

export function Evaluators(props) {
  const dispatch = useDispatch();
  const { data, loading, detail } = useSelector(evaluatorsSelector);
  const [currentEvaluator, setCurrentEvaluator] = useState("");
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (props.id) {
      dispatch(getEvaluators(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <>
      <Badge.Ribbon text="Evaluations" color="cyan">
        <div className="container" style={{ paddingBottom: "10px" }}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data}
              className="pt-12"
              grid={{ gutter: 12, column: 5 }}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    size="small"
                    type="inner"
                    title={item.role.toUpperCase()}
                    extra={
                      item.evaluations.length > 0 ? (
                        <span
                          className={styles.link}
                          onClick={() => {
                            setModalOpened(true);
                            setCurrentEvaluator(item.name);
                            dispatch(getEvaluation(item.evaluations[0].id));
                          }}
                        >
                          Show Evaluation
                        </span>
                      ) : (
                        <span className={styles.not}>Not Evaluated</span>
                      )
                    }
                  >
                    <Link to={`/users/${item.id}`}>
                      <Avatar src={item.avatar} />
                      &nbsp;&nbsp;
                      {item.name}
                    </Link>
                  </Card>
                </List.Item>
              )}
            />
          )}
        </div>
      </Badge.Ribbon>
      <Modal
        visible={modalOpened}
        title={currentEvaluator}
        onCancel={() => setModalOpened(false)}
        width={700}
      >
        <List
          itemLayout="horizontal"
          dataSource={detail.data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={<span>{item.question}</span>}
                description={
                  (item.a_number ? item.a_number + " out of 4 stars" : "") +
                  (item.a_text ? item.a_text : "") +
                  item.a_options.join(", ") +
                  (item.a_bool === false ? (item.a_bool ? "Yes" : "No") : "")
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}
