import { Col, Input, message, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAll, usersSelector } from "./usersSlice";

const { Search } = Input;
const { Column } = Table;
const { Text } = Typography;
const PAGE_SIZE = 50;

export function Users() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, totalPages, errors } = useSelector(usersSelector);

  useEffect(() => {
    dispatch(fetchAll(`page=${currentPage}`));
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  const handleSearch = (search) => {
    setCurrentPage(1);
    dispatch(fetchAll(`page=1&search=${search}`));
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchAll(`page=${page}`));
  };

  const getRole = (role) => {
    if (role === "startup" || role === "fs_startup") {
      return "Founder";
    } else if (role) {
      return role[0].toUpperCase() + role.slice(1);
    }
  };

  return (
    <>
      <div className="container">
        <Row justify="center" align="middle">
          <Col span={12}>
            <Search
              placeholder="Search by name or email..."
              onSearch={handleSearch}
              enterButton
              allowClear
            />
          </Col>
        </Row>
      </div>
      <div className="container pb-0">
        <Table
          dataSource={data}
          loading={loading}
          size="small"
          bordered
          pagination={{
            position: ["bottomRight"],
            onChange: onPageChange,
            pageSize: PAGE_SIZE,
            total: totalPages * PAGE_SIZE,
            showSizeChanger: false,
            current: currentPage,
          }}
          scroll={{ x: 1000, y: 500 }}
        >
          <Column title="ID" dataIndex="id" key="id" />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            width="150"
            fixed="left"
            render={(name, user) => (
              <Link to={`/users/${user.id}`}>{name}</Link>
            )}
          />
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="Phone" dataIndex="phone" key="phone" />
          <Column
            title="Role"
            dataIndex="role"
            key="role"
            render={(role) => getRole(role)}
          />
        </Table>
      </div>
    </>
  );
}
