import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import companiesReducer from "./features/companies/companiesSlice";
import startupsReducer from "./features/startups/startupsSlice";
import tagsReducer from "./features/tags/tagsSlice";
import usersReducer from "./features/users/usersSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    startups: startupsReducer,
    users: usersReducer,
    tags: tagsReducer,
    companies: companiesReducer,
  },
});
