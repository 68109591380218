import { Badge, Empty, Spin, Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Users.module.css";
import { getSkills, skillsSelector } from "../usersSlice";

export function Skills(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(skillsSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getSkills(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <>
      <Badge.Ribbon text="Skills" color="cyan">
        <div className="container" style={{ paddingBottom: "10px" }}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="pt-12">
              {data.length > 0 ? (
                data.map((t) => (
                  <Tag color="magenta" key={t.id} className="mb-5">
                    {t.name}
                  </Tag>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
}
