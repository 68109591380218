import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, authSelector } from "./authSlice";
import { useHistory } from "react-router-dom";
import { Form, Button, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../assets/img/logo.png";
import styles from "./Auth.module.css";

export function Login() {
  const { currentUser, loading, errors } = useSelector(authSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (currentUser.id) {
      history.push("/startups");
    }
  }, [currentUser]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  return (
    <div className={styles.loginContainer}>
      <Form
        name="normal_login"
        className={styles.loginForm}
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item className="text-center">
          <img src={logo} alt="logo" width="250" />
        </Form.Item>
        <br />
        <Form.Item
          name="email"
          type="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="prefixIcon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="prefixIcon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="w-100"
          loading={loading}
        >
          Login
        </Button>
        {/* <div className="text-center mt-15">
          <Link to="/about">Forgot Password?</Link>
        </div> */}
        {/* <Divider>
          <span className="dividerText">OR</span>
        </Divider>
        <Button type="primary" ghost className="w-100">
          Create New Account
        </Button> */}
      </Form>
      <br />
    </div>
  );
}
