import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  loading: false,
  totalPages: 0,
  error: null,
  details: {
    data: {},
    loading: false,
    errors: null,
  },
  vendors: {
    data: [],
    loading: false,
    errors: null,
  },
  partners: {
    data: [],
    loading: false,
    errors: null,
  },
  people: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const fetchAll = createAsyncThunk(
  "companies/fetchAll",
  async (data, thunk) => {
    try {
      let response = await API.companies.get(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getDetails = createAsyncThunk(
  "companies/details",
  async (data, thunk) => {
    try {
      let response = await API.companies.details(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getVendors = createAsyncThunk(
  "companies/vendors",
  async (data, thunk) => {
    try {
      let response = await API.companies.vendors(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getPartners = createAsyncThunk(
  "companies/partners",
  async (data, thunk) => {
    try {
      let response = await API.companies.partners(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getPeople = createAsyncThunk(
  "companies/people",
  async (data, thunk) => {
    try {
      let response = await API.companies.people(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  extraReducers: {
    [fetchAll.pending]: (state) => {
      state.loading = true;
    },
    [fetchAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.companies.map((s) => {
        s.key = s.id;
        return s;
      });
      state.totalPages = action.payload.total_pages;
    },
    [fetchAll.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [getDetails.pending]: (state) => {
      state.details.loading = true;
    },
    [getDetails.fulfilled]: (state, action) => {
      state.details.loading = false;
      state.details.data = action.payload;
    },
    [getDetails.rejected]: (state, action) => {
      state.details.loading = false;
      state.details.errors = action.payload.errors;
    },
    [getVendors.pending]: (state) => {
      state.vendors.loading = true;
    },
    [getVendors.fulfilled]: (state, action) => {
      state.vendors.loading = false;
      state.vendors.data = action.payload.vendors;
    },
    [getVendors.rejected]: (state, action) => {
      state.vendors.loading = false;
      state.vendors.errors = action.payload.errors;
    },
    [getPartners.pending]: (state) => {
      state.partners.loading = true;
    },
    [getPartners.fulfilled]: (state, action) => {
      state.partners.loading = false;
      state.partners.data = action.payload.partners;
    },
    [getPartners.rejected]: (state, action) => {
      state.partners.loading = false;
      state.partners.errors = action.payload.errors;
    },
    [getPeople.pending]: (state) => {
      state.people.loading = true;
    },
    [getPeople.fulfilled]: (state, action) => {
      state.people.loading = false;
      state.people.data = action.payload.people;
    },
    [getPeople.rejected]: (state, action) => {
      state.people.loading = false;
      state.people.errors = action.payload.errors;
    },
  },
});

export const companiesSelector = (state) => state.companies;
export const detailsSelector = (state) => state.companies.details;
export const vendorsSelector = (state) => state.companies.vendors;
export const partnersSelector = (state) => state.companies.partners;
export const peopleSelector = (state) => state.companies.people;

export default companiesSlice.reducer;
