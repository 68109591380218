import { Avatar, Badge, List, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../Startups.module.css";
import { getPartners, partnersSelector } from "../startupsSlice";

export function Partners(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(partnersSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getPartners(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <>
      <Badge.Ribbon text="Partners" color="cyan">
        <div className="container" style={{ paddingBottom: "10px" }}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Link to={`/companies/${item.id}`}>
                        <Avatar src={item.logo} />{" "}
                      </Link>
                    }
                    title={
                      <Link to={`/companies/${item.id}`}>
                        <span className="text-link">{item.name}</span>
                      </Link>
                    }
                    description={item.category}
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
}
