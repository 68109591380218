import { Avatar, Badge, Card, List, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../Startups.module.css";
import { getInvestors, investorsSelector } from "../startupsSlice";

export function Investors(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(investorsSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getInvestors(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <>
      <Badge.Ribbon text="Investors" color="cyan">
        <div className="container" style={{ paddingBottom: "10px" }}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data}
              className="pt-12"
              grid={{ gutter: 12, column: 2 }}
              renderItem={(item) => (
                <List.Item>
                  <Card size="small">
                    <Link to={`/users/${item.id}`}>
                      <Avatar src={item.avatar} />
                      &nbsp;&nbsp;
                      {item.name}
                    </Link>
                  </Card>
                </List.Item>
              )}
            />
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
}
