import { InfoCircleTwoTone } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Customers } from "./cards/Customers";
import { Doc } from "./cards/Doc";
import { Evaluators } from "./cards/Evaluators";
import { Investors } from "./cards/Investors";
import { Partners } from "./cards/Partners";
import { Sellers } from "./cards/Sellers";
import { Team } from "./cards/Team";
import { Info } from "./Info";
import styles from "./Startups.module.css";
import { detailsSelector, getDetails } from "./startupsSlice";

const { Paragraph } = Typography;

export function StartupDetails(props) {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(detailsSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getDetails(props.id));
    }
  }, [props.id, dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  const openInfo = (startup) => {
    Modal.info({
      title: startup.name,
      content: <Info startup={startup} />,
      autoFocusButton: null,
      width: 600,
      closable: true,
    });
  };

  return (
    <>
      <Row gutter="24">
        <Col span="16">
          <div className="container">
            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Row gutter="24" align="top" wrap>
                  <Col span="5" className="text-center">
                    <Avatar src={data.logo} size={140} />
                  </Col>
                  <Col span="14">
                    <Space direction="vertical">
                      <div className={styles.title}>{data.name}</div>
                      <div className={styles.subTitle}>{data.anchor_pitch}</div>
                      <div>
                        {data.countries ? data.countries.join(", ") : ""}
                      </div>
                      <div>
                        {data.category}{" "}
                        {data.sub_categories && data.sub_categories.length > 0
                          ? "-> " + data.sub_categories.join(", ")
                          : ""}
                      </div>
                      {data.info && (
                        <>
                          <div>
                            <Button
                              type="dashed"
                              size="small"
                              className="mt-5"
                              icon={<InfoCircleTwoTone />}
                              onClick={() => openInfo(data)}
                            >
                              More Info
                            </Button>
                          </div>
                        </>
                      )}
                    </Space>
                  </Col>
                  <Col span="5">
                    {data.tags?.map((t) => (
                      <Tag color="magenta" key={t.id} className="mb-5">
                        {t.name}
                      </Tag>
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
        <Col span="8">
          <Doc id={props.id} />
        </Col>
      </Row>
      <Row gutter="24">
        <Col span={24}>
          <Evaluators id={props.id} />
        </Col>
      </Row>
      <Row gutter="24">
        <Col span={8}>
          <Team id={props.id} />
        </Col>
        <Col span={8}>
          <Customers id={props.id} />
        </Col>
        <Col span={8}>
          <Partners id={props.id} />
        </Col>
      </Row>
      <Row gutter="24">
        <Col span={12}>
          <Investors id={props.id} />
        </Col>
        <Col span={12}>
          <Sellers id={props.id} />
        </Col>
      </Row>
    </>
  );
}
