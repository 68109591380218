import {
  DollarOutlined,
  FilePptOutlined,
  FileSearchOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { Badge, Button, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Startups.module.css";
import { docsSelector, getDocs } from "../startupsSlice";

export function Doc(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(docsSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getDocs(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <Badge.Ribbon text="Documents" color="cyan">
      <div className="container" style={{ paddingBottom: "10px" }}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="pt-12">
            {data.pitch_deck && (
              <Button
                className={styles.docBtn}
                href={data.pitch_deck}
                target="_blank"
                icon={<FilePptOutlined />}
                size="large"
              >
                Pitch Deck
              </Button>
            )}
            {data.finance && (
              <Button
                className={styles.docBtn}
                href={data.finance}
                target="_blank"
                icon={<DollarOutlined />}
                size="large"
              >
                Financial Model
              </Button>
            )}
            {data.report && (
              <Button
                className={styles.docBtn}
                href={data.report}
                target="_blank"
                icon={<FileSearchOutlined />}
                size="large"
              >
                Sector Report
              </Button>
            )}
            {data.diagnostic && (
              <Button
                className={styles.docBtn}
                href={data.diagnostic}
                target="_blank"
                icon={<ProjectOutlined />}
                size="large"
              >
                Diagnostic
              </Button>
            )}
          </div>
        )}
      </div>
    </Badge.Ribbon>
  );
}
