import { Space, Typography } from "antd";
import React from "react";
const { Text, Paragraph } = Typography;

export function Info(props) {
  return (
    <div className="mt-20" style={{ height: "400px", overflowY: "scroll" }}>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>Do you guys have a website?</Text>
          <Text>{props.startup?.info?.web}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            What problem are you solving and how? (Your Anchor Pitch)
          </Text>
          <Text>{props.startup?.anchor_pitch}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>When was your company founded?</Text>
          <Text>{props.startup?.info?.founded}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>How does your founding team look like?</Text>
          <Text>{props.startup?.info?.f_team_makeup}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>What is the average work experience of founders?</Text>
          <Text>{props.startup?.info?.ceo_exp}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            Out of the above 'average experience of the founders', how many
            years is domain specific experience in the industry where your
            company is operating?
          </Text>
          <Text>{props.startup?.info?.ceo_exp_rel}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>How many full time members does the company have?</Text>
          <Text>{props.startup?.info?.fl_time}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            What's your biggest unfair advantage over other startups?
          </Text>
          <Text>{props.startup?.info?.unfair}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>What motivated you to start this company?</Text>
          <Text>{props.startup?.info?.approach}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            Can you explain the target addressable market size for
            product/service?
          </Text>
          <Text>{props.startup?.info?.market}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>What business model are you in?</Text>
          <Text>{props.startup?.info?.biz_model}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>What is the stage of your product evolution?</Text>
          <Text>{props.startup?.info?.prod_stage}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            How much revenue was secured by the company in last 12 months? (in
            USD)
          </Text>
          <Text>{props.startup?.info?.rev_current}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>What investment stage is your startup at?</Text>
          <Text>{props.startup?.info?.inv_stage}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            Any recognised notable investors/accelerators/incubators?
          </Text>
          <Text>{props.startup?.info?.accelerator}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            Are you associated/contributing to below industries or using some of
            those practices in your company?
          </Text>
          <Text>{props.startup?.info?.esg_practice}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>
            Which of those Sustainable Development Goals are you developing
            towards?
          </Text>
          <Text>{props.startup?.info?.esg_goal}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>Any media/awards significant recognition?</Text>
          <Text>{props.startup?.info?.awards}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Space size={2} direction="vertical">
          <Text strong>Where did you hear about us?</Text>
          <Text>{props.startup?.info?.referral}</Text>
        </Space>
      </Paragraph>
    </div>
  );
}
