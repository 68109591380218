import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

export const fetchAll = createAsyncThunk(
  "startups/fetchAll",
  async (data, thunk) => {
    try {
      let response = await API.startups.get(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getDetails = createAsyncThunk(
  "startups/details",
  async (data, thunk) => {
    try {
      let response = await API.startups.details(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getDocs = createAsyncThunk(
  "startups/docs",
  async (data, thunk) => {
    try {
      let response = await API.startups.docs(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getTeam = createAsyncThunk(
  "startups/team",
  async (data, thunk) => {
    try {
      let response = await API.startups.team(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomers = createAsyncThunk(
  "startups/customers",
  async (data, thunk) => {
    try {
      let response = await API.startups.customers(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getPartners = createAsyncThunk(
  "startups/partners",
  async (data, thunk) => {
    try {
      let response = await API.startups.partners(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const exportAll = createAsyncThunk(
  "startups/export",
  async (data, thunk) => {
    try {
      let response = await API.startups.get(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const update = createAsyncThunk(
  "startups/update",
  async (data, thunk) => {
    try {
      let response = await API.startups.update(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createTag = createAsyncThunk(
  "startups/createTag",
  async (data, thunk) => {
    try {
      let response = await API.tags.post_startup(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const removeTag = createAsyncThunk(
  "startups/removeTag",
  async (data, thunk) => {
    try {
      let response = await API.tags.del_startup(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const fetchStartupReport = createAsyncThunk(
  "startups/fetchStartupReport",
  async (id, thunk) => {
    try {
      let response = await API.startups.report(id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getEvaluators = createAsyncThunk(
  "startups/evaluators",
  async (data, thunk) => {
    try {
      let response = await API.startups.evaluators(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getEvaluation = createAsyncThunk(
  "startups/evaluation",
  async (data, thunk) => {
    try {
      let response = await API.startups.evaluation(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getInvestors = createAsyncThunk(
  "startups/investors",
  async (data, thunk) => {
    try {
      let response = await API.startups.investors(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getSellers = createAsyncThunk(
  "startups/sellers",
  async (data, thunk) => {
    try {
      let response = await API.startups.sellers(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  totalPages: 0,
  totalCount: 0,
  errors: null,
  report: {
    data: {},
    loading: false,
  },
  details: {
    data: {},
    loading: false,
    errors: null,
  },
  docs: {
    data: {},
    loading: false,
    errors: null,
  },
  team: {
    data: [],
    loading: false,
    errors: null,
  },
  customers: {
    data: [],
    loading: false,
    errors: null,
  },
  partners: {
    data: [],
    loading: false,
    errors: null,
  },
  evaluators: {
    data: [],
    loading: false,
    errors: null,
    detail: {
      data: [],
      loading: false,
      errors: null,
    },
  },
  investors: {
    data: [],
    loading: false,
    errors: null,
  },
  sellers: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const startupsSlice = createSlice({
  name: "startups",
  initialState,
  extraReducers: {
    [fetchAll.pending]: (state) => {
      state.loading = true;
    },
    [fetchAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.startups.map((s) => {
        s.key = s.id;
        return s;
      });
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [fetchAll.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [fetchStartupReport.pending]: (state) => {
      state.report.loading = true;
    },
    [fetchStartupReport.fulfilled]: (state, action) => {
      state.report.loading = false;
      state.report.data = action.payload;
    },
    [fetchStartupReport.rejected]: (state, action) => {
      state.report.loading = false;
      state.errors = action.payload.errors;
    },
    [update.pending]: (state) => {
      state.loading = true;
    },
    [update.fulfilled]: (state, action) => {
      state.data = state.data.map((s) => {
        if (s.id === action.payload.id) {
          return action.payload;
        }
        return s;
      });
      state.loading = false;
    },
    [update.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [createTag.pending]: (state) => {
      state.loading = true;
    },
    [createTag.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = state.data.map((s) => {
        if (action.payload.startup_id === s.id) {
          s.tags = action.payload.tags;
        }
        return s;
      });
    },
    [createTag.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [removeTag.pending]: (state) => {
      state.loading = true;
    },
    [removeTag.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = state.data.map((s) => {
        if (action.payload.startup_id === s.id) {
          s.tags = action.payload.tags;
        }
        return s;
      });
    },
    [removeTag.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [exportAll.pending]: (state) => {
      state.loading = true;
    },
    [exportAll.fulfilled]: (state, action) => {
      state.loading = false;
      const url = window.URL.createObjectURL(new Blob([action.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `export-${new Date().toLocaleString()}.csv`
      );
      document.body.appendChild(link);
      link.click();
    },
    [exportAll.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [getDetails.pending]: (state) => {
      state.details.loading = true;
    },
    [getDetails.fulfilled]: (state, action) => {
      state.details.loading = false;
      state.details.data = action.payload;
    },
    [getDetails.rejected]: (state, action) => {
      state.details.loading = false;
      state.details.errors = action.payload.errors;
    },
    [getDocs.pending]: (state) => {
      state.docs.loading = true;
    },
    [getDocs.fulfilled]: (state, action) => {
      state.docs.loading = false;
      state.docs.data = action.payload;
    },
    [getDocs.rejected]: (state, action) => {
      state.docs.loading = false;
      state.docs.errors = action.payload.errors;
    },
    [getTeam.pending]: (state) => {
      state.team.loading = true;
    },
    [getTeam.fulfilled]: (state, action) => {
      state.team.loading = false;
      state.team.data = action.payload;
    },
    [getTeam.rejected]: (state, action) => {
      state.team.loading = false;
      state.team.errors = action.payload.errors;
    },
    [getCustomers.pending]: (state) => {
      state.customers.loading = true;
    },
    [getCustomers.fulfilled]: (state, action) => {
      state.customers.loading = false;
      state.customers.data = action.payload;
    },
    [getCustomers.rejected]: (state, action) => {
      state.customers.loading = false;
      state.customers.errors = action.payload.errors;
    },
    [getPartners.pending]: (state) => {
      state.partners.loading = true;
    },
    [getPartners.fulfilled]: (state, action) => {
      state.partners.loading = false;
      state.partners.data = action.payload;
    },
    [getPartners.rejected]: (state, action) => {
      state.partners.loading = false;
      state.partners.errors = action.payload.errors;
    },
    [getEvaluators.pending]: (state) => {
      state.evaluators.loading = true;
    },
    [getEvaluators.fulfilled]: (state, action) => {
      state.evaluators.loading = false;
      state.evaluators.data = action.payload.evaluators;
    },
    [getEvaluators.rejected]: (state, action) => {
      state.evaluators.loading = false;
      state.evaluators.errors = action.payload.errors;
    },
    [getEvaluation.pending]: (state) => {
      state.evaluators.detail.loading = true;
    },
    [getEvaluation.fulfilled]: (state, action) => {
      state.evaluators.detail.loading = false;
      state.evaluators.detail.data = action.payload.evaluation;
    },
    [getEvaluation.rejected]: (state, action) => {
      state.evaluators.detail.loading = false;
      state.evaluators.detail.errors = action.payload.errors;
    },
    [getInvestors.pending]: (state) => {
      state.investors.loading = true;
    },
    [getInvestors.fulfilled]: (state, action) => {
      state.investors.loading = false;
      state.investors.data = action.payload.investors;
    },
    [getInvestors.rejected]: (state, action) => {
      state.investors.loading = false;
      state.investors.errors = action.payload.errors;
    },
    [getSellers.pending]: (state) => {
      state.sellers.loading = true;
    },
    [getSellers.fulfilled]: (state, action) => {
      state.sellers.loading = false;
      state.sellers.data = action.payload.sellers;
    },
    [getSellers.rejected]: (state, action) => {
      state.sellers.loading = false;
      state.sellers.errors = action.payload.errors;
    },
  },
});

export const startupsSelector = (state) => state.startups;
export const detailsSelector = (state) => state.startups.details;
export const docsSelector = (state) => state.startups.docs;
export const teamSelector = (state) => state.startups.team;
export const customersSelector = (state) => state.startups.customers;
export const partnersSelector = (state) => state.startups.partners;
export const evaluatorsSelector = (state) => state.startups.evaluators;
export const investorsSelector = (state) => state.startups.investors;
export const sellersSelector = (state) => state.startups.sellers;

export default startupsSlice.reducer;
