import {
  Avatar,
  Divider,
  Typography,
  Input,
  Button,
  Space,
  Spin,
  message,
  Upload,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { update, updateAvatar, authSelector } from "../auth/authSlice";
import styles from "./Profile.module.css";
import { EditOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

export function Profile() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const { currentUser, loading, errors } = useSelector(authSelector);

  const changeUser = (data) => {
    setUser(Object.assign({}, user, data));
    dispatch(update(data));
  };

  const changeAvatar = (data) => {
    var fd = new FormData();
    fd.append("avatar", data.file);
    dispatch(updateAvatar(fd));
  };

  const changePassword = () => {
    if (confPass !== newPass) {
      message.error("Passwords don't match");
      return;
    }
    dispatch(update({ password: newPass }));
  };

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (newPass) {
      setNewPass("");
      setConfPass("");
      message.success("Password Updated Successfully");
    }
    setUser(currentUser);
  }, [currentUser]);

  return (
    <div className="container-sm text-center">
      <Spin spinning={loading}>
        <Avatar size={75} src={currentUser.avatar_url} />
        <div className="mt-5">
          <Upload
            accept="image/*"
            customRequest={changeAvatar}
            showUploadList={false}
            className="pointer"
          >
            <EditOutlined className="txt-primary" />
            {/* <Button size="small" icon={<UploadOutlined />}>
              Change Avatar
            </Button> */}
          </Upload>
        </div>
        <div className={`${styles.content} mt-15`}>
          <Paragraph
            className="f-18 mb-10"
            strong
            editable={{
              onChange: (val) => {
                if (val.length > 0) {
                  changeUser({ name: val });
                }
              },
            }}
          >
            {user.name}
          </Paragraph>
          <Paragraph
            className="f-18"
            strong
            editable={{
              onChange: (val) => {
                if (val.length > 0) {
                  changeUser({ email: val });
                }
              },
            }}
          >
            {user.email}
          </Paragraph>
        </div>
        <Divider />
        <div className={`${styles.pContent} mt-30`}>
          <Space direction="vertical" size="middle" className="w-100">
            <Input.Password
              type="password"
              placeholder="New password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
            />
            <Input.Password
              type="password"
              placeholder="Confirm New password"
              value={confPass}
              onChange={(e) => setConfPass(e.target.value)}
            />
            <Button
              type="primary"
              className="w-100"
              onClick={() => changePassword()}
            >
              Change Password
            </Button>
          </Space>
        </div>
      </Spin>
    </div>
  );
}
