import { LinkedinOutlined } from "@ant-design/icons";
import { Badge, Col, message, Row, Space, Spin, Tag } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Experiences } from "./cards/Experiences";
import { Skills } from "./cards/Skills";
import styles from "./Users.module.css";
import { detailsSelector, getDetails } from "./usersSlice";

export function UserDetails(props) {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(detailsSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getDetails(props.id));
    }
  }, [props.id, dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  return (
    <>
      <Row gutter="24">
        <Col span="16">
          <div className="container">
            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Row gutter="24" align="top" wrap>
                  <Col span="6" className="text-center">
                    <Avatar src={data?.avatar} size={150} />
                  </Col>
                  <Col span="18">
                    <Space direction="vertical">
                      <div className={styles.title}>
                        {data.name}
                        {data.li && (
                          <>
                            &nbsp;&nbsp;
                            <a href={data.li} target="_blank" rel="noreferrer">
                              <LinkedinOutlined />
                            </a>
                          </>
                        )}
                        &nbsp;&nbsp;
                        <Badge
                          count={data.role ? data.role.toUpperCase() : ""}
                        />
                      </div>
                      <div>
                        {data.email}&nbsp;&nbsp;{data.phone}
                      </div>
                      {data.college && (
                        <div>
                          {data.college.name}&nbsp;&nbsp;
                          {data.college.elite && (
                            <Tag color="magenta">Elite University</Tag>
                          )}
                        </div>
                      )}
                      {data.exp && (
                        <>
                          <div>{`${data.exp} years of experience`}</div>
                        </>
                      )}
                      {data.startups && data.startups.length > 0 && (
                        <div>
                          <Link to={`/startups/${data.startups[0].id}`}>
                            {data.startups[0].name}
                          </Link>
                        </div>
                      )}
                    </Space>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
        <Col span="8">
          <Skills id={props.id} />
        </Col>
      </Row>
      <Row gutter="24">
        <Col span={8}>
          <Experiences id={props.id} />
        </Col>
      </Row>
    </>
  );
}
