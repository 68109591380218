import { PlusOutlined } from "@ant-design/icons";
import { Input, message, Modal, Spin, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTag, fetchTags, removeTag, tagsSelector } from "./tagsSlice";

const { Title } = Typography;

export function Tags() {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(tagsSelector);
  const [newTagModal, setNewTagModal] = useState(false);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    dispatch(fetchTags());
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  const handleTagDelete = (e, t) => {
    e.preventDefault();
    dispatch(removeTag(t.id));
  };

  return (
    <div className="container-sm">
      <div className="text-center">
        <Title level={4} className="mb-30">
          Manage Startup Tags
        </Title>
        <Spin spinning={loading}>
          {data.map((t) => (
            <Tag
              color="magenta"
              key={t.id}
              closable
              className="mb-5"
              onClose={(e) => handleTagDelete(e, t)}
            >
              {t.name}
            </Tag>
          ))}
          <Tag
            color="blue"
            className="pointer"
            onClick={() => setNewTagModal(true)}
          >
            <PlusOutlined />
          </Tag>
        </Spin>
      </div>
      <Modal
        title="Create New Tag"
        visible={newTagModal}
        onOk={() => {
          if (newTag.length > 0) {
            dispatch(createTag({ name: newTag }));
            setNewTag("");
            setNewTagModal(false);
          }
        }}
        onCancel={() => setNewTagModal(false)}
        width={300}
      >
        <Input
          value={newTag}
          placeholder="Tag name"
          onChange={(e) => setNewTag(e.target.value)}
        />
      </Modal>
    </div>
  );
}
