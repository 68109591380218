import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchTags = createAsyncThunk("tags/fetchAll", async (_, thunk) => {
  try {
    let response = await API.tags.get();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const createTag = createAsyncThunk(
  "tags/createTag",
  async (data, thunk) => {
    try {
      let response = await API.tags.post(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const removeTag = createAsyncThunk(
  "tags/removeTag",
  async (data, thunk) => {
    try {
      let response = await API.tags.del(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  extraReducers: {
    [fetchTags.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchTags.rejected]: (state, action) => {
      state.errors = action.payload.errors;
    },
    [createTag.pending]: (state, action) => {
      state.loading = true;
    },
    [createTag.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [createTag.rejected]: (state, action) => {
      state.errors = action.payload.errors;
    },
    [removeTag.pending]: (state, action) => {
      state.loading = true;
    },
    [removeTag.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [removeTag.rejected]: (state, action) => {
      state.errors = action.payload.errors;
    },
  },
});

export const tagsSelector = (state) => state.tags;

export default tagsSlice.reducer;
