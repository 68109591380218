import { Col, message, Row, Space, Spin } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Partners } from "./cards/Partners";
import { People } from "./cards/People";
import { Vendors } from "./cards/Vendors";
import styles from "./Companies.module.css";
import { detailsSelector, getDetails } from "./companiesSlice";

export function CompanyDetails(props) {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(detailsSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getDetails(props.id));
    }
  }, [props.id, dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  return (
    <>
      <Row gutter="24">
        <Col span="24">
          <div className="container">
            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Row gutter="24" align="top" wrap>
                  <Col span="4" className="text-center">
                    <Avatar src={data?.logo} size={100} />
                  </Col>
                  <Col span="20">
                    <Space direction="vertical">
                      <div className={styles.title}>{data.name}</div>
                      <div>
                        {data.category}{" "}
                        {data.sub_categories && data.sub_categories.length > 0
                          ? "-> " + data.sub_categories.join(", ")
                          : ""}
                      </div>
                    </Space>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row gutter="24">
        <Col span={8}>
          <People id={props.id} />
        </Col>
        <Col span={8}>
          <Vendors id={props.id} />
        </Col>
        <Col span={8}>
          <Partners id={props.id} />
        </Col>
      </Row>
    </>
  );
}
