import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  loading: false,
  totalPages: 0,
  error: null,
  details: {
    data: {},
    loading: false,
    errors: null,
  },
  skills: {
    data: [],
    loading: false,
    errors: null,
  },
  experiences: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const fetchAll = createAsyncThunk(
  "users/fetchAll",
  async (data, thunk) => {
    try {
      let response = await API.users.get(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getDetails = createAsyncThunk(
  "users/details",
  async (data, thunk) => {
    try {
      let response = await API.users.details(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getSkills = createAsyncThunk(
  "users/skills",
  async (data, thunk) => {
    try {
      let response = await API.users.skills(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getExperiences = createAsyncThunk(
  "users/experiences",
  async (data, thunk) => {
    try {
      let response = await API.users.experiences(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [fetchAll.pending]: (state) => {
      state.loading = true;
    },
    [fetchAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.users.map((s) => {
        s.key = s.id;
        return s;
      });
      state.totalPages = action.payload.total_pages;
    },
    [fetchAll.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [getDetails.pending]: (state) => {
      state.details.loading = true;
    },
    [getDetails.fulfilled]: (state, action) => {
      state.details.loading = false;
      state.details.data = action.payload;
    },
    [getDetails.rejected]: (state, action) => {
      state.details.loading = false;
      state.details.errors = action.payload.errors;
    },
    [getSkills.pending]: (state) => {
      state.skills.loading = true;
    },
    [getSkills.fulfilled]: (state, action) => {
      state.skills.loading = false;
      state.skills.data = action.payload.skills;
    },
    [getSkills.rejected]: (state, action) => {
      state.skills.loading = false;
      state.skills.errors = action.payload.errors;
    },
    [getExperiences.pending]: (state) => {
      state.experiences.loading = true;
    },
    [getExperiences.fulfilled]: (state, action) => {
      state.experiences.loading = false;
      state.experiences.data = action.payload.experiences;
    },
    [getExperiences.rejected]: (state, action) => {
      state.experiences.loading = false;
      state.experiences.errors = action.payload.errors;
    },
  },
});

export const usersSelector = (state) => state.users;
export const detailsSelector = (state) => state.users.details;
export const skillsSelector = (state) => state.users.skills;
export const experiencesSelector = (state) => state.users.experiences;

export default usersSlice.reducer;
