import { Col, Row, Typography } from "antd";
import React from "react";
import styles from "./Startups.module.css";

const { Text } = Typography;

const legend = {
  founded: "Time since inception",
  f_team_makeup: "Core team construct",
  ceo_exp: "Founding team experience",
  ceo_exp_rel: "Founder's relevant experience",
  fl_time: "Overall team strength",
  unfair: "Key execution advantage",
  market: "Market opportunity size",
  prod_stage: "Product market maturity",
  rev_current: "The revenue road",
  inv_stage: "My investment stage",
};

export function Percentile(props) {
  const getRows = () => {
    let rows = [];
    let data = props.data.screening;
    if (data === undefined) {
      return;
    }
    for (const key in legend) {
      rows.push(
        <Row align="middle" className="mt-20" key={key}>
          <Col span={8}>
            <Text strong>{legend[key]}</Text>
          </Col>
          <Col span={16} className="text-right">
            <Row>
              <Col span={6}>
                <div
                  className={data[key] < 25 ? styles.percSelected : styles.perc}
                >
                  Sub 25
                </div>
              </Col>
              <Col span={6}>
                <div
                  className={
                    data[key] < 50 && data[key] >= 25
                      ? styles.percSelected
                      : styles.perc
                  }
                >
                  25-50
                </div>
              </Col>
              <Col span={6}>
                <div
                  className={
                    data[key] < 75 && data[key] >= 50
                      ? styles.percSelected
                      : styles.perc
                  }
                >
                  50-75
                </div>
              </Col>
              <Col span={6}>
                <div
                  className={
                    data[key] >= 75 ? styles.percSelected : styles.perc
                  }
                >
                  Above 75
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return rows;
  };

  return <div className={styles.reportSection}>{getRows()}</div>;
}
