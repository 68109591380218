import {
  CheckOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
  InfoCircleTwoTone,
  PlusOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { countries } from "../../countries";
import { sanifyDate, serialize } from "../../helpers";
import { fetchTags, tagsSelector } from "../tags/tagsSlice";
import { Info } from "./Info";
import { Percentile } from "./Percentile";
import {
  createTag,
  exportAll,
  fetchAll,
  fetchStartupReport,
  removeTag,
  startupsSelector,
  update,
} from "./startupsSlice";

const { Column } = Table;
const { Search } = Input;
const { Text } = Typography;
const { Option } = Select;
const PAGE_SIZE = 50;

const categories = [
  "AdTech",
  "AgriTech",
  "AI",
  "CleanTech",
  "Construction Tech",
  "ConsumerTech",
  "Consumer Digital",
  "CyberSecurity",
  "EduTech",
  "EdTech",
  "Enterprise Tech",
  "EnterpriseTech",
  "FinTech",
  "FoodTech",
  "Gaming",
  "HealthTech",
  "HR Tech",
  "IoT",
  "Life Sciences",
  "Logistics Tech",
  "Manufacturing Tech",
  "Marketing Tech",
  "Media & Entertainment",
  "Mobility Tech",
  "Retail Tech",
  "Robotics",
  "Sports Tech",
  "Technology",
  "Travel Tech",
  "Other",
];

const defaultFilters = {
  categories: [],
  countries: [],
  tags: [],
};

export function Startups() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, totalPages, totalCount, report, errors } =
    useSelector(startupsSelector);
  const tags = useSelector(tagsSelector);
  const [chosenStartup, setChosenStartup] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [chooseType, setChooseType] = useState("search");
  const [searchString, setSearchString] = useState("");
  const [newTagModal, setNewTagModal] = useState({});

  useEffect(() => {
    dispatch(fetchAll(`page=${currentPage}`));
    dispatch(fetchTags());
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
    if (chooseType === "search") {
      handleSearch(searchString, page);
    } else {
      handleFilters(page);
    }
  };

  const handleSearch = (search, page) => {
    setChooseType("search");
    setFilters(defaultFilters);
    dispatch(fetchAll(`page=${page}&search=${search}`));
  };

  const handleFilters = (page) => {
    setChooseType("filter");
    setSearchString("");
    dispatch(fetchAll(`page=${page}&${serialize(filters)}`));
  };

  const downloadFile = () => {
    dispatch(exportAll(`page=1&export=csv&${serialize(filters)}`));
  };

  const handleTagDelete = (e, t, s) => {
    e.preventDefault();
    dispatch(
      removeTag({
        startup_id: s.id,
        tag_id: t.id,
      })
    );
  };

  const openInfo = (startup) => {
    Modal.info({
      title: startup.name,
      content: <Info startup={startup} />,
      autoFocusButton: null,
      width: 600,
      closable: true,
    });
  };

  const openPercentile = (startup) => {
    setChosenStartup(startup);
    dispatch(fetchStartupReport(startup.id));
  };

  useEffect(() => {
    if (report.data.screening && report.loading === false) {
      Modal.info({
        title: chosenStartup.name,
        content: <Percentile data={report.data} />,
        autoFocusButton: null,
        width: 1000,
        closable: true,
      });
    }
  }, [report]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  return (
    <>
      <div className="container">
        <Row justify="center" align="middle">
          <Col span={4}>
            <Search
              placeholder="Search by name..."
              onChange={(e) => setSearchString(e.target.value)}
              onSearch={(val) => {
                setCurrentPage(1);
                handleSearch(val, 1);
              }}
              value={searchString}
              enterButton
              allowClear
            />
          </Col>
          <Col span={2} className="text-center">
            <Text disabled>OR</Text>
          </Col>
          <Col span={18}>
            <Row justify="start" align="middle" gutter={[16, 16]}>
              <Col span={5}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Category Filter"
                  value={filters["categories"]}
                  onChange={(data) => {
                    let updated = { ...filters };
                    if (data.length > 0) {
                      updated["categories"] = data;
                    } else {
                      delete updated.categories;
                    }
                    setFilters(updated);
                  }}
                  className="w-100"
                >
                  {categories.map((c) => (
                    <Option key={c}>{c}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={5}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Country Filter"
                  value={filters["countries"]}
                  onChange={(data) => {
                    let updated = { ...filters };
                    if (data.length > 0) {
                      updated["countries"] = data;
                    } else {
                      delete updated.countries;
                    }
                    setFilters(updated);
                  }}
                  className="w-100"
                >
                  {countries.map((c) => (
                    <Option key={c}>{c}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={5}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Tag Filter"
                  value={filters["tags"]}
                  onChange={(data) => {
                    let updated = { ...filters };
                    if (data.length > 0) {
                      updated["tags"] = data;
                    } else {
                      delete updated.tags;
                    }
                    setFilters(updated);
                  }}
                  className="w-100"
                >
                  {tags.data.map((t) => (
                    <Option key={t.id} value={t.id}>
                      {t.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={5}>
                <Select
                  allowClear
                  placeholder="Approved"
                  value={
                    filters["approved"]
                      ? "Yes"
                      : filters["approved"] === false
                      ? "No"
                      : null
                  }
                  onChange={(data) => {
                    let updated = { ...filters };
                    if (data !== undefined) {
                      updated["approved"] = data === "Yes" ? true : false;
                    } else {
                      delete updated.approved;
                    }
                    setFilters(updated);
                  }}
                  className="w-100"
                >
                  {["Yes", "No"].map((t) => (
                    <Option key={t} value={t}>
                      {t}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrentPage(1);
                    handleFilters(1);
                  }}
                >
                  Apply filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="container pb-0">
        <div className="text-left mb-10">
          <Text strong className="text-highlight">
            {totalCount} startups found
          </Text>
          &nbsp;&nbsp;
          <Popconfirm
            title={`Do you want to export ${totalCount} startups?`}
            onConfirm={downloadFile}
            okText="Export"
            cancelText="No"
          >
            <Button
              type="dashed"
              shape="circle"
              size="small"
              icon={<CloudDownloadOutlined />}
            />
          </Popconfirm>
        </div>
        <Table
          dataSource={data}
          loading={loading}
          size="small"
          bordered
          pagination={{
            position: ["bottomRight"],
            onChange: onPageChange,
            pageSize: PAGE_SIZE,
            total: totalPages * PAGE_SIZE,
            showSizeChanger: false,
            current: currentPage,
          }}
          scroll={{ x: 1000, y: 500 }}
        >
          <Column title="ID" dataIndex="id" key="id" />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            // width="150"
            // fixed="left"
            render={(name, startup) => (
              <Link to={`/startups/${startup.id}`}>{name}</Link>
            )}
          />
          <Column title="Category" dataIndex="category" key="category" />
          <Column
            title="Countries"
            dataIndex="countries"
            key="countries"
            render={(c) => {
              console.log(c);
              return <span>{c.join(", ")}</span>;
            }}
          />
          <Column
            title="Info"
            dataIndex="info"
            key="info"
            render={(info, startup) => {
              let buttons = [];
              if (startup.pitch_deck) {
                buttons.push(
                  <Button
                    type="dashed"
                    size="small"
                    href={startup.pitch_deck}
                    target="_blank"
                    key="pitch"
                  >
                    Pitch Deck
                  </Button>
                );
              }
              if (info) {
                buttons.push(
                  <Button
                    type="dashed"
                    size="small"
                    icon={<InfoCircleTwoTone />}
                    className="mt-5"
                    key="info"
                    onClick={() => openInfo(startup)}
                  >
                    More Info
                  </Button>
                );
              }
              return <>{buttons}</>;
            }}
          />
          <Column
            title="Created By"
            dataIndex="owners"
            key="owners"
            render={(owners) => (
              <>
                {owners.map((o) => (
                  <div key={o.id}>
                    <div>{o.name}</div>
                    <div className="f-12">{o.email}</div>
                    <div className="f-12">{o.phone}</div>
                  </div>
                ))}
              </>
            )}
          />
          <Column
            title="Tags"
            dataIndex="tags"
            key="tags"
            render={(tags, startup) => (
              <>
                {tags.map((t) => (
                  <Tag
                    color="magenta"
                    key={t.id}
                    closable
                    className="mb-5"
                    onClose={(e) => handleTagDelete(e, t, startup)}
                  >
                    {t.name}
                  </Tag>
                ))}
                <Tag
                  color="blue"
                  className="pointer"
                  onClick={(e) =>
                    setNewTagModal({
                      startup_id: startup.id,
                    })
                  }
                >
                  <PlusOutlined />
                </Tag>
              </>
            )}
          />
          <Column
            title="Stage"
            dataIndex="stage"
            key="stage"
            render={(stage, startup) => {
              if (stage === 0) {
                return <div>Screening In Progress</div>;
              } else {
                return (
                  <>
                    <Button
                      type="dashed"
                      size="small"
                      onClick={() => openPercentile(startup)}
                    >
                      Score: {startup.scores.screening}
                    </Button>
                    <div className="mt-5 f-12">Screening Submitted</div>
                  </>
                );
              }
            }}
          />
          <Column
            title="Approved"
            dataIndex="approved"
            key="approved"
            render={(approved, startup) => (
              <>
                {approved === true || approved === false ? (
                  <>
                    <Tag color={approved ? "green" : "red"}>
                      {approved ? "Yes" : "No"}
                    </Tag>
                    <Popconfirm
                      title={
                        approved
                          ? `Are you sure you want to reject ${startup.name}?`
                          : `Are you sure you want to approve ${startup.name}?`
                      }
                      onConfirm={() =>
                        dispatch(
                          update({
                            startup_id: startup.id,
                            data: { approved: !approved },
                          })
                        )
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tag color="blue" className="pointer">
                        <SwapOutlined />
                      </Tag>
                    </Popconfirm>
                  </>
                ) : (
                  <>
                    <Popconfirm
                      title={`Are you sure you want to approve ${startup.name}?`}
                      onConfirm={() =>
                        dispatch(
                          update({
                            startup_id: startup.id,
                            data: { approved: true },
                          })
                        )
                      }
                      okText="Approve"
                      cancelText="No"
                    >
                      <Tag color="green" className="pointer">
                        <CheckOutlined />
                      </Tag>
                    </Popconfirm>
                    <Popconfirm
                      title={`Are you sure you want to reject ${startup.name}?`}
                      onConfirm={() =>
                        dispatch(
                          update({
                            startup_id: startup.id,
                            data: { approved: false },
                          })
                        )
                      }
                      okText="Reject"
                      cancelText="No"
                    >
                      <Tag color="red" className="pointer">
                        <CloseOutlined />
                      </Tag>
                    </Popconfirm>
                  </>
                )}
              </>
            )}
          />
          <Column
            title="Created At"
            dataIndex="created_at"
            key="created_at"
            render={(date) => sanifyDate(date)}
          />
        </Table>
      </div>
      <Modal
        title="Add New Tag"
        visible={newTagModal && newTagModal.startup_id ? true : false}
        onOk={() => {
          dispatch(createTag(newTagModal));
          setNewTagModal({});
        }}
        onCancel={() => setNewTagModal({})}
        width={300}
      >
        <Select
          placeholder="Choose Tag"
          value={newTagModal.tag_id}
          onChange={(data) => {
            setNewTagModal({ ...newTagModal, tag_id: data });
          }}
          className="w-100"
        >
          {tags.data.map((t) => (
            <Option key={t.id} value={t.id}>
              {t.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}
