export const serialize = function (obj) {
  let str = "";
  for (let p in obj) {
    if (Array.isArray(obj[p])) {
      obj[p].forEach((e) => {
        str = str + `${p}[]=${e}&`;
      });
    } else {
      str = str + `${p}=${obj[p]}&`;
    }
  }
  return str.slice(0, -1);
};

export const sanifyDate = (str) => {
  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(str).toLocaleString("en-US", options);
};
