import axios from "axios";

const REDIRECT_TO = "/sign_in";

const storeAuthKeys = (headers) => {
  if (headers["access-token"]) {
    localStorage.setItem("uid", headers["uid"]);
    localStorage.setItem("access-token", headers["access-token"]);
    localStorage.setItem("client", headers["client"]);
    localStorage.setItem("expiry", headers["expiry"]);
    localStorage.setItem("token-type", headers["token-type"]);
  }
};

axios.interceptors.request.use(
  (config) => {
    if (!config.noAuth) {
      config.headers = {
        ...config.headers,
        accept: "application/json",
        uid: localStorage.getItem("uid"),
        "access-token": localStorage.getItem("access-token"),
        client: localStorage.getItem("client"),
        locale: "en",
        dashboard: true,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    storeAuthKeys(response.headers);
    if (response.config.clearCookies) {
      localStorage.clear();
      window.location.replace(REDIRECT_TO);
    }
    return response;
  },
  function (error) {
    storeAuthKeys(error.response.headers);
    if (!error.response.config.noAuth) {
      if (error.response.status === 401) {
        //Unauthorised user: Redirect to login, clear local storage
        localStorage.clear();
        window.location.replace(REDIRECT_TO);
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
