import axios from "axios";

let HOST = "http://localhost:3000";
if (process.env.NODE_ENV === "production") {
  HOST = "https://backend.startup-o.com";
}

const API = {
  auth: {
    login: (data) =>
      axios.post(`${HOST}/sessions/sign_in`, data, {
        noAuth: true,
      }),
    register: (data) =>
      axios.post(`${HOST}/sessions`, data, {
        noAuth: true,
      }),
    logout: () =>
      axios.delete(`${HOST}/sessions/sign_out`, {
        clearCookies: true,
      }),
    validate: () => axios.get(`${HOST}/sessions/validate_token`),
    update: (data) => axios.put(`${HOST}/sessions`, data),
    updateAvatar: (data) => axios.put(`${HOST}/profile/update_avatar`, data),
  },
  startups: {
    get: (filters) => axios.get(`${HOST}/admins/startups?${filters}`),
    details: (id) => axios.get(`${HOST}/admins/startups/${id}`),
    docs: (id) => axios.get(`${HOST}/startups/${id}/docs`),
    team: (id) => axios.get(`${HOST}/startups/${id}/teams`),
    customers: (id) => axios.get(`${HOST}/startups/${id}/customers`),
    partners: (id) => axios.get(`${HOST}/startups/${id}/partners`),
    update: (data) =>
      axios.put(`${HOST}/admins/startups/${data.startup_id}`, data.data),
    report: (id) => axios.get(`${HOST}/startups/${id}/reports`),
    evaluators: (id) => axios.get(`${HOST}/admins/startups/${id}/evaluators`),
    evaluation: (id) => axios.get(`${HOST}/admins/evaluations/${id}`),
    investors: (id) => axios.get(`${HOST}/admins/startups/${id}/investors`),
    sellers: (id) => axios.get(`${HOST}/admins/startups/${id}/sellers`),
  },
  users: {
    get: (filters) => axios.get(`${HOST}/admins/users?${filters}`),
    details: (id) => axios.get(`${HOST}/admins/users/${id}`),
    skills: (id) => axios.get(`${HOST}/admins/users/${id}/skills`),
    experiences: (id) => axios.get(`${HOST}/admins/users/${id}/experiences`),
  },
  companies: {
    get: (filters) => axios.get(`${HOST}/companies?${filters}`),
    details: (id) => axios.get(`${HOST}/companies/${id}`),
    people: (id) => axios.get(`${HOST}/companies/${id}/people`),
    vendors: (id) => axios.get(`${HOST}/companies/${id}/vendors`),
    partners: (id) => axios.get(`${HOST}/companies/${id}/partners`),
  },
  tags: {
    get: () => axios.get(`${HOST}/admins/tags`),
    post: (data) => axios.post(`${HOST}/admins/tags`, data),
    del: (id) => axios.delete(`${HOST}/admins/tags/${id}`),
    post_startup: (data) =>
      axios.post(
        `${HOST}/admins/startups/${data.startup_id}/tag/${data.tag_id}`
      ),
    del_startup: (data) =>
      axios.delete(
        `${HOST}/admins/startups/${data.startup_id}/tag/${data.tag_id}`
      ),
  },
  profile: {
    edit: (data) => axios.put(`${HOST}/sessions`, data),
  },
};

export default API;
