import { Avatar, Badge, List, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../Companies.module.css";
import { getPeople, peopleSelector } from "../companiesSlice";

export function People(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(peopleSelector);

  useEffect(() => {
    if (props.id) {
      dispatch(getPeople(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <>
      <Badge.Ribbon text="People" color="cyan">
        <div className="container" style={{ paddingBottom: "10px" }}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Link to={`/users/${item.id}`}>
                        <Avatar src={item.avatar} />
                      </Link>
                    }
                    title={
                      <Link to={`/users/${item.id}`}>
                        <span className="text-link">{item.name}</span>
                      </Link>
                    }
                    description={item.designation}
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
}
