import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  loggedIn: false,
  currentUser: {},
  loading: false,
  errors: null,
};

export const login = createAsyncThunk("user/login", async (data, thunk) => {
  try {
    let response = await API.auth.login(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const logout = createAsyncThunk("user/logout", async (_, thunk) => {
  try {
    let response = await API.auth.logout();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const validate = createAsyncThunk("user/validate", async (_, thunk) => {
  try {
    let response = await API.auth.validate();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const update = createAsyncThunk("user/update", async (data, thunk) => {
  try {
    let response = await API.auth.update(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateAvatar = createAsyncThunk(
  "user/update_avatar",
  async (data, thunk) => {
    try {
      let response = await API.auth.updateAvatar(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.currentUser = action.payload.data;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.loggedIn = false;
      state.errors = action.payload.errors;
    },
    [validate.pending]: (state) => {
      state.loading = true;
    },
    [validate.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload.data;
    },
    [validate.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [update.pending]: (state) => {
      state.loading = true;
    },
    [update.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload.data;
    },
    [update.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors.full_messages;
    },
    [updateAvatar.pending]: (state) => {
      state.loading = true;
    },
    [updateAvatar.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = {
        ...state.currentUser,
        avatar_url: action.payload.avatar,
      };
    },
    [updateAvatar.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors.full_messages;
    },
  },
});

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
